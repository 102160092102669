import { forwardRef, ReactNode } from 'react';
import Slider from 'react-slick';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import { ItemCard } from './item-card';
import { Button } from './button';
import { JsxElement } from 'typescript';

interface WebContentGalleryProps {
    data: object | any;
}

const ImageContainer = ({ currentGalleryContent,  children}:any) => (<>
    <div
        className="w-full h-[483px] md:h-[555px] bg-cover bg-center text-primary-midnight hidden md:flex"
        style={{ backgroundImage: `url(${currentGalleryContent?.largeBackgroundImage?.fields?.file?.url})` }}>
        {children}
    </div >
    <div className="w-full h-[483px] md:h-[555px] bg-cover bg-center text-primary-midnight flex md:hidden"
        style={{ backgroundImage: `url(${currentGalleryContent?.mobileBackgroundImage?.fields?.file?.url})` }}>
        {children}
    </div>
</>)

export const WebContentGallery = forwardRef<HTMLDivElement, WebContentGalleryProps>(({ data }, ref) => {
    let galleryContent = data?.galleryContent;
    let currentGalleryContent = galleryContent[0]?.fields;
    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2.25,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.25,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    arrows: false,
                    dots: true,
                },
            },
        ],
    };

    const channelOrSponsorLogos = currentGalleryContent?.channelOrSponsorLogos;
    const bannerTextColor = currentGalleryContent?.showImageGradient ? 'text-white' : 'text-black';
    return (
        <div ref={ref} className="flex flex-col font-skyBook">
            <div className="justify-center items-center mx-auto">
                <div className="flex-col justify-center items-center flex w-[343px] md:w-[632px] mb-[16px]">
                    <div className="text-center text-primary-day-light-50 text-[18px] md:text-lg font-normal uppercase md:leading-[18px] tracking-[1.8px] my-2">
                        {data?.largeGalleryName}
                    </div>
                    <div className="text-center text-white text-[36px] md:text-[60px] font-skyBlack font-bold leading-[36px] md:leading-[60px]">
                        {data?.heading}
                    </div>
                    <div className="text-center text-white text-base md:text-xl leading-normal my-4 md:my-6">
                    {documentToReactComponents(data?.detailsdescription )}
                    </div>
                </div>
            </div>
            <ImageContainer currentGalleryContent={currentGalleryContent}>
                <div
                    className={classNames(
                        'flex flex-col justify-end md:justify-center items-center md:items-start gap-2 md:gap-4 px-8 md:ml-[100px] lg:ml-[188px] pb-4 md:pb-16 w-full md:max-w-[524px]',
                        bannerTextColor,
                        { 'bg-gradient-to-t md:bg-gradient-to-r from-[#01023b]': currentGalleryContent.showImageGradient },
                    )}>
                    {channelOrSponsorLogos?.length > 0 &&
                        (channelOrSponsorLogos ?? [])?.map(({ fields }: any) => (
                            <div className="flex gap-2 items-center overflow-x-auto">
                                <img className="" src={fields?.file?.url} alt={fields?.file?.fileName} />
                            </div>
                        ))}
                    <div className="text-[36px] md:text-[56px] leading-[43px] md:leading-[67px] font-skyBlack font-extrabold text-center md:text-left lg:max-w-[524px]">
                        {documentToReactComponents(currentGalleryContent?.contentsection)}
                    </div>
                    <div className="text-base md:text-[20px] font-bold leading-normal text-center md:text-left lg:max-w-[524px]">
                        {documentToReactComponents(currentGalleryContent?.sectionDescription)}
                    </div>
                    <Button
                        onClick={() => window.open(currentGalleryContent?.ctaLink)}
                        className={classNames('bg-bright-blue text-white rounded-full w-[150px] h-[42px] mt-10 md:mt-16')}>
                        {currentGalleryContent?.ctaName}
                    </Button>
                </div>
            </ImageContainer>

            <div className="slider my-8 md:mt-16 md:mb-0 pl-3 md:px-[72px] w-full">
                <Slider {...settings}>
                    {galleryContent.map((item: any, index: number) => {
                        return <ItemCard key={index} data={item.fields} />;
                    })}
                </Slider>
            </div>
        </div>
    );
});

