import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Footer, Header } from '@sky-tv-group/components';
import { useAuth0, getUser } from './store/authStore/authStore';
import useStore from './store';

import { Home, Page, ErrorPage,NewSkyPodPage } from './templates'
import {
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";


const App = () => {
  const logout = useAuth0(s => s.logout);
  const login = useAuth0(s => s.login);
  const { isAuthenticated } = useAuth0();
  const navLinks = useStore(s => s.navLinks);
  const profile = useStore(s => s.profile);
  const userData = getUser();
  const fetchNavLinks = useStore(s => s.fetchNavLinks);
  const logoutHandler = async () => {
    logout();
  };

 
  React.useEffect(() => {
    fetchNavLinks();
  }, [fetchNavLinks]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Navigate to="/products" replace />,
      errorElement: <ErrorPage  />,
    },
    {
      path: "/products",
      element: <Home />,
      errorElement: <ErrorPage  />,
    },
    {
      path: "/products/sky-pod",
      element: <NewSkyPodPage />,
      errorElement: <ErrorPage  />,
    },
    {
      path: "/products/:product",
      element: <Page />,
      errorElement: <ErrorPage  />,
    },
    {
      path: "/products/:product/:page",
      element: <Page />,
      errorElement: <ErrorPage  />,
    },
  ]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={window.location.protocol + '//' + window.location.host} />
      </Helmet>
      <Header
        isSignedIn={isAuthenticated}
        name={profile?.displayName || profile?.firstName}
        SessionClickHandler={isAuthenticated ? logoutHandler : login}
        links={navLinks}
      />
      <RouterProvider router={router} />
      <Footer links={navLinks} />
    </>
  );
};

export default App;
