import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ItemCardProps {
  data: object | any;
}

export const ItemCard = (props: ItemCardProps) => {
  return (
    <div className="flex flex-col mx-1 md:mx-2">
      <img className='max-w-[255px] mx-h-[145px] md:max-w-[415px] md:max-h-[235px]' alt="card-img" src={props.data?.contentThumbnail?.fields?.file?.url} />
      <div className="px-4 md:px-8 justify-start items-start mt-4 md:mt-6 gap-4 flex flex-col">
        <div className="text-left text-white text-2xl md:text-4xl leading-[28px] md:leading-[43.20px] font-skyBlack font-black">
          {props.data?.contentTitle}
        </div>
        <div className="text-left text-white leading-tight text-[14px] md:text-[16px]">
          {documentToReactComponents(props.data?.contentDescription)}
        </div>
      </div>
    </div>
  );
};