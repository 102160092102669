import TechSpecTile from './tech-spec-tile';

interface SkyPodOverviewProps {
  data: object | any;
}

export const SkyPodOverview = (props: SkyPodOverviewProps) => {
  let heading = props.data.heading;
  let subHeading = props.data.subHeading;
  let bundleCards = props.data.bundleCards;

  return (
    <div className="py-4 md:py-8 bg-white gap-16 ">
      <div className="max-w-[1500px] mx-auto mt-4 md:mt-8 w-full">
        <div className="h-[86px] px-auto">
          <div className="h-[86px] mx-auto">
            <div className="text-center text-secondary-blue text-sm md:text-lg font-medium font-skyBook uppercase leading-[18px] tracking-widest">
              {heading}
            </div>
            <div className="mx-auto text-center text-[#00013a] w-[300px] md:w-full text-4xl md:text-6xl font-extrabold font-skyBlack leading-8 md:leading-[60px] my-2">
              {subHeading}
            </div>
          </div>
        </div>
        <div className="flex flex-wrap flex-col md:flex-row mt-4 md:mt-8 mx-4 lg:mx-20 gap-y-4">
          {bundleCards.map((item: any) => (
            <TechSpecTile data={item.fields} />
          ))}
        </div>
      </div>
    </div>
  );
};

