import { createClient } from 'contentful'
import { Layout } from '../templates';
import { useParams } from 'react-router-dom';
import useSWR from 'swr'

const previewMode = process.env.REACT_APP_PREVIEW_MODE as string;
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID as string
let deliveryToken = process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN as string;

if (previewMode === 'preview') {
    deliveryToken = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN as string 
}

const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT as string 
let host = process.env.REACT_APP_CONTENTFUL_CDN_HOST as string;

if (previewMode === 'preview') {
    host = process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST as string;
}

export const client = createClient({
    space: spaceId,
    accessToken: deliveryToken,
    environment: environment,
    host: host
})

const queryContentful = async (product: string, page: string) => {

    // const paramsPath = product ? '/products-2023/'+product : page ? '/products-2023/'+product+'/'+page : '/products-2023';
    
    const paramsPath = product ? '/products/'+product : page ? '/products/'+product+'/'+page : '/products';
    
    console.log(paramsPath)
    const entryItems = await client.getEntries({ 
                                                content_type: 'productHardware', 
                                                'fields.pageSlug': paramsPath.replace(/\/undefined/g,""),
                                                limit: 1, 
                                                include: 10 
                                            })

                                            // console.log("LLL:",  paramsPath.replace(/\/undefined/g,""), entryItems)
    if(entryItems.items[0]?.fields) {
        return entryItems.items[0].fields
    }
    return {status: false}
}

const GetContent = () => {
    const { product, page } = useParams();
    const { data } = useSWR(product+"/"+page, queryContentful)
    if(data) {
        return (
            <>
                <Layout content={data} />
            </>
        );
    }
    return null; 
};

export default GetContent;

