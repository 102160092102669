import { useLocation } from "react-router-dom";
import useSWR from "swr";
import { NewSkyPodLayout } from "./layout";
import { SubMenu } from "../../components";
import { client } from "../../components/GetContent";
 
const queryContentful = async () => {
    const entryItems = await client.getEntries({
        content_type: 'podComposePage',
        'fields.slug': 'sky-product-re-branding-sky-pod-page',
        limit: 1,
        include: 10,
    })
    if (entryItems.items[0]?.fields) {
        return entryItems.items[0].fields
    }
    return { status: false }
}

const NewSkyPodPage: React.FC = () => {
    const { pathname } = useLocation();
    const { data } = useSWR(pathname, queryContentful);
    if (data) {
        const subMenus = { content: { pageSlug: pathname } }
        return <main>
            <SubMenu data={subMenus} />
            <NewSkyPodLayout content={data} />
        </main>
    }
    return null;
}

export default NewSkyPodPage;