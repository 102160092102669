import RailCarousel from './rail-carousel';

interface RailCarouselGroupProps {
    data: object | any;
}
const potraitCards: boolean = true;
export const RailCarouselGroup: React.FC<RailCarouselGroupProps> = ({ data }) => {
    return (
        <div className="flex flex-col gap-16 items-center text-white text-center">
            <div className="max-w-[854px] flex flex-col gap-2">
                <div className="text-[14px] font-skybook leading-[14px] xl:text-[18px] uppercase tracking-wider text-primary-day-light-50 mb-2">
                    {data.heading}
                </div>
                <div className="text-[36px] md:text-[60px] font-skyBlack font-bold leading-[36px] md:leading-[60px]">
                    {data.subheading}
                </div>
            </div>

            {data?.largeRails && (
                <RailCarousel
                    data={data.largeRails.fields}
                    className="w-[168px] md:w-[200px] aspect-[9/16]"
                    showHeading
                    potraitCards={potraitCards}
                />
            )}

            {data?.smallContentRails.map((item: any, index: number) =>
                index === 0 ? (
                    <RailCarousel
                        key={index}
                        data={item.fields}
                        className="w-[99px] h-[56px] md:w-[186px] md:h-[105px] rounded-lg"
                    />
                ) : (
                    <RailCarousel key={index} data={item.fields} className="w-[48px] h-[48px] md:w-[80px] md:h-[80px]" />
                ),
            )}
        </div>
    );
};

export default RailCarouselGroup;
