import { useLocation } from 'react-router-dom';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames';
import down_arrow from '../../../assets/icons/click-to-scroll.gif';
import { Button } from './button';

export const Banner = (props: any) => {
    let bannerData = props.data;
    const location = useLocation();
    const shouldShowDiv = location.pathname.includes('/support');
    const isHomePage = location.pathname === '/';
    const InlineImage = (data: any) => {
        return (
            <img className="float-left mr-2 max-w-[32px] max-h-[32px]" src={'https:' + data.data.url} alt={data.data.title} />
        );
    };

    const options = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node: any) => {
                let hasImage = false;
                if (node.content.some((contentNode: any) => contentNode.value !== '')) {
                    return (
                        <div className="flex flex-wrap items-start mt-2">
                            {node.content.map((contentNode: any, index: number) => {
                                if (contentNode.nodeType === 'embedded-asset-block') {
                                    hasImage = true;
                                    return (
                                        <div key={index} className="flex items-start">
                                            {documentToReactComponents(contentNode, options)}
                                        </div>
                                    );
                                }
                                if (contentNode.nodeType === 'text') {
                                    return (
                                        <p key={index} className={classNames('mb-2', { 'ml-2': hasImage })}>
                                            {contentNode.value}
                                        </p>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const { file } = node.data.target.fields;
                return <InlineImage data={file} />;
            },
            // Add other custom renderers as needed
        },
    };

    const handleDownArrowClick = () => {
        props.handleBundleCardsScroll();
    };

    const hasBannerImage = bannerData?.bannerImage?.fields?.file?.url;

    return (
        <div>
            <div
                className={classNames(
                    'pt-6 md:pt-12 xl:pt-[70px] px-4 sm:px-4 md:px-20 md:pr-5 xl:pl-[189px] lg:pr-20 2xl:px-[228px]',
                    { 'text-center': !hasBannerImage },
                )}>
                <div className={classNames('flex flex-col sm:flex-row justify-between items-center', { 'justify-center': !hasBannerImage })}>
                    {/* Left Part */}
                    <div
                        className={classNames('flex-1 flex flex-col gap-6 items-center md:items-start', {
                            'md:max-w-[299px] lg:max-w-[350px] xl:max-w-[576px]': hasBannerImage && isHomePage,
                            'justify-center items-center lg:max-w-[614px]': !hasBannerImage,
                            'max-w-[299px] md:max-w-[468px]': hasBannerImage,
                            //   'md:max-w-[458px]': !isHomePage,
                        })}>
                        <div className="flex flex-col gap-2">
                            <h4
                                className={classNames(
                                    'tracking-widest text-center text-[14px] font-skyBook leading-[14px]; xl:text-[18px] xl:leading-[18px] text-primary-day-light-50',
                                    {
                                        'sm:text-left': !shouldShowDiv,
                                    },
                                )}>
                                {bannerData?.productTitle}
                            </h4>
                            <h1
                                className={classNames(
                                    'text-[36px] font-skyBold leading-[32.4px] font-extrabold text-center xl:text-[80px] xl:leading-[72px] text-white',
                                    { 'sm:text-left': !shouldShowDiv },
                                )}>
                                {documentToReactComponents(bannerData?.description)}
                            </h1>
                        </div>
                        <div
                            className={classNames('text-white flex flex-col text-[16px] md:text-[20px] items-center md:items-start')}>
                            {documentToReactComponents(bannerData.extraDescription, options)}
                            {documentToReactComponents(bannerData.secondaryInformation, options)}
                        </div>
                        {bannerData?.ctaName && <Button
                            onClick={() => window.open(bannerData?.ctaLink)}
                            className={classNames('bg-white text-bright-blue rounded-full w-[150px] h-[42px]')}>
                            {bannerData?.ctaName}
                        </Button>}
                    </div>
                    {/* Right Part */}
                    {hasBannerImage && (
                        <div
                            className={classNames('flex w-[343px] md:w-[360px] lg:w-[496px] xl:w-[631px] 2xl:w-[796px] justify-end', {
                                'w-[343px]': !isHomePage,
                            })}>
                            <img src={bannerData?.bannerImage?.fields?.file?.url} alt={props.heading} />
                        </div>
                    )}
                </div>
            </div>
            {!shouldShowDiv && (
                <div className="flex flex-col justify-center items-center text-xs mt-4 sm:justify-center">
                    <div className="text-primary-day-light text-[16px] font-skyBold leading-[20px]">scroll</div>
                    <div onClick={handleDownArrowClick}>
                        <img src={down_arrow} alt="Down arrow" className="cursor-pointer" />
                    </div>
                </div>
            )}
        </div>
    );
};
