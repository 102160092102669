import React from 'react';
import RailCards from '../rail-cards/rail-cards';
import { Settings as ReactSlickSettings } from 'react-slick';
import { ReactComponent as RightArrow } from '../../../../assets/icons/icon_right_arrow.svg';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/icon_left_arrow.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export interface RailItem {
    title?: string;
    image: string;
    link?: string;
}

interface RailCarouselProps {
    data: object | any;
    className: string;
    showHeading?: boolean;
    potraitCards?: boolean;
}
const customSettings: ReactSlickSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    swipeToSlide: true,
    variableWidth: true,
    infinite: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
};

const RailCarousel: React.FC<RailCarouselProps> = ({ data, className, showHeading, potraitCards }) => {
    let contentTiles = data.contentTiles;
    const imagesArrayNew = contentTiles.map(
        (item: { fields: { image: { fields: { file: { url: any } } } } }) => item.fields.image.fields.file.url,
    );
    const videoUrlsArray = contentTiles.map((item: { fields: { videoUrl: any } }) => item.fields.videoUrl);
    const headingsArray = contentTiles.map((item: { fields: { heading: string } }) => item.fields.heading);

    return (
        <div className="flex flex-col gap-8">
            <div>
                {data?.heading && (
                    <h2 className="text-[16px] font-skyBook leading-[20px] xl:text-[20px] mb-2 px-4 lg:px-16">
                        {documentToReactComponents(data.heading)}
                    </h2>
                )}
                {data?.description && (
                    <h2 className="text-[14px] font-skyBook leading-[14px] mb-4 px-4">{documentToReactComponents(data.description)}</h2>
                )}
            </div>
            <div className="relative left-20">
                <RailCards
                    images={imagesArrayNew}
                    headings={headingsArray}
                    sliderSettings={customSettings}
                    className={className}
                    showHeading={showHeading}
                    videoUrls={videoUrlsArray}
                    potraitCards={potraitCards}
                />
            </div>
        </div>
    );
};

export default RailCarousel;
