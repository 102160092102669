import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface TechSpecTileProps {
  data: object | any;
}

const TechSpecTile = (props: TechSpecTileProps) => {
  let heading = props.data.title;
  let description = props.data.shortDescription;
  let productImage = props.data?.image?.fields?.file?.url ?? '';
  let imagePosition = props.data?.imagePosition;

  return (
    <div className="text-center md:flex-[0_0_50%] lg:flex-[0_0_33.3333%]">
      <div className="max-w-[416px] p-4 md:p-8 bg-white rounded-lg border border-[#c4c4c4] font-skyBook mx-2 my-2 h-full">
        <div className={`flex ${heading ? 'flex-row gap-4' : 'flex-col'}`}>
          <div
            className={`flex-col justify-start items-start gap-4 ${imagePosition !== 'Center' && productImage && heading ? 'w-1/2' : ''}`}>
            <div className="text-left text-[#00013a] text-[22px] md:text-2xl font-black font-skyBlack leading-7 mb-4">
              {heading}
            </div>
            {imagePosition === 'Center' && (
              <img alt="product-img" className="rounded max-h-[210px] m-auto" src={productImage} />
            )}
            {
              <div className="text-left text-[#00013a] text-base leading-tight">
                {documentToReactComponents(description)}
              </div>
            }
          </div>
          {imagePosition === 'Left' && (
            <div className={`max-h-[210px] w-1/2 } `}>
              <img alt="product-img" className="rounded max-h-[210px] m-auto" src={productImage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TechSpecTile;
