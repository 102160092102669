import * as React from 'react';
import cn from 'classnames';

export interface ButtonProps
    extends React.ButtonHTMLAttributes<HTMLButtonElement> { }

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...props }, ref) => {
    return <button className={cn('inline-flex py-[14px] px-[24px] items-center justify-center whitespace-nowrap rounded-full text-[16px] font-[700] leading-[20px] cursor-pointer disabled:cursor-not-allowed', className)} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button };
