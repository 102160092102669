import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Settings as ReactSlickSettings } from 'react-slick';
import classNames from 'classnames';

// Define the props interface
interface RailCardsProps {
  images: string[];
  videoUrls?: string[]; // Add video URLs
  sliderSettings?: ReactSlickSettings;
  className?: string;
  headings?: string[];
  showHeading?: boolean;
  potraitCards?: boolean;
  bundleRailCards?: boolean;
}

// Define the component
const RailCards: React.FC<RailCardsProps> = ({
  images,
  videoUrls,
  sliderSettings = {},
  className = 'w-28 h-16',
  headings,
  showHeading = false,
  potraitCards,
  bundleRailCards,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const defaultSettings: ReactSlickSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    swipeToSlide: true,
    variableWidth: true,
    infinite: true,
    arrows: true,
  };

  const finalSettings = { ...defaultSettings, ...sliderSettings };

  return (
    <div className={`slider-container my-2 ${potraitCards ? 'potrait-rail-cards py-6' : ''} relative`}>
      <Slider {...finalSettings}>
        {images.map((imgSrc, index) => {
          const hasVideo = videoUrls && videoUrls[index];
          const isHovered = hoveredIndex === index;

          return (
            <div
              key={index}
              className="relative group"
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}>
              {/* Display image */}
              <img
                className={classNames(
                  'rounded object-cover transition-opacity duration-300 mr-2 lg:mr-4',
                  className,
                  { 'mr-1 lg:mr-1 xl:mr-2': bundleRailCards },
                  { 'opacity-0': potraitCards && isHovered && hasVideo },
                  { 'opacity-100': !(potraitCards && isHovered && hasVideo) },
                  { 'scale-up': potraitCards && isHovered }, // Apply scale-up on hover
                )}
                src={imgSrc}
                alt={`slide-${index}`}
              />

              {/* Display video on hover if available */}
              {potraitCards && hasVideo && isHovered && (
                <video
                  className={classNames(
                    'absolute inset-0 w-full h-full object-cover transition-opacity duration-300',
                    className,
                    { 'opacity-100': isHovered },
                    { 'opacity-0': !isHovered },
                    { 'scale-up': potraitCards && isHovered }, // Apply scale-up on hover
                  )}
                  src={videoUrls[index]}
                  autoPlay
                  loop
                  muted
                />
              )}

              {/* Display heading */}
              {showHeading && headings && headings[index] && (
                <div className={classNames('absolute left-0 bottom-0 p-6 flex', className)}>
                  <span className="self-end w-full text-base md:text-lg leading-5 md:leading-6 font-skyBold">
                    {headings[index]}
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default RailCards;
