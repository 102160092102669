import React from "react";
import { ReactComponent as BackArrow } from '../assets/icons/back-arrow-small.svg'

interface SubMenuProps {
    data: object | any | null;
}

const SubMenu: React.FC<SubMenuProps> = ({data}) => {

    if(data.content.pageSlug !== "/products") {
        switch(data.content.pageSlug) {
        case "/products/new-sky-box":
            return (
                <div className="bg-gray-light">
                    <div className="ml-0 lg:ml-16 lg:pl-6 xl:ml-16 xl:pl-12 overflow-x-scroll scrollbar-hide">
                        <ul className="w-[600px] md:w-auto">
                            <li className="inline-block mx-6"><a href="/products" className="text-xl md:text-lg"><BackArrow className="inline-block relative -top-[3px]"/> Back to Overview</a></li>
                            <li className="inline-block mx-6 pt-4 pb-2 border-b-2 border-blue-pure"><span className="font-skyBold text-blue-pure text-xl md:text-lg">New Sky Box</span></li>
                            <li className="inline-block mx-6"><a href="/products/new-sky-box/tech-specs" className="text-xl md:text-lg">Tech Specs</a></li>
                            <li className="inline-block mx-6"><a href="/products/upcoming-releases" className="text-xl md:text-lg">Upcoming Releases</a></li>
                        </ul>
                    </div>
                </div>
            )
        break;
        case "/products/sky-pod":
            return (
                <div className="bg-gray-light">
                    <div className="ml-0 lg:ml-16 lg:pl-6 xl:ml-16 xl:pl-12 overflow-x-scroll scrollbar-hide">
                        <ul className="w-[600px] md:w-auto">
                            <li className="inline-block mx-6"><a href="/products" className="text-xl md:text-lg"><BackArrow className="inline-block"/> Back to Overview</a></li>
                            <li className="inline-block mx-6 pt-4 pb-2 border-b-2 border-blue-pure"><span className="font-skyBold text-blue-pure text-xl md:text-lg">Sky Pod</span></li>
                            {/* <li className="inline-block mx-6"><a href="/products/sky-pod/tech-specs" className="text-xl md:text-lg">Tech Specs</a></li> */}
                            <li className="inline-block mx-6"><a href="/products/upcoming-releases" className="text-xl md:text-lg">Upcoming Releases</a></li>
                        </ul>
                    </div>
                </div>
            )
        break;
        case "/products/new-sky-box/tech-specs":
            return (
                <div className="bg-gray-light">
                    <div className="ml-0 lg:ml-16 lg:pl-6 xl:ml-16 xl:pl-12 overflow-x-scroll scrollbar-hide">
                        <ul className="w-[600px] md:w-auto">
                            <li className="inline-block mx-6"><a href="/products" className="text-xl md:text-lg"><BackArrow className="inline-block relative -top-[3px]"/> Back to Overview</a></li>
                            <li className="inline-block mx-6"><a href="/products/new-sky-box" className="text-xl md:text-lg">New Sky Box</a></li>
                            <li className="inline-block mx-6 pt-4 pb-2 border-b-2 border-blue-pure"><span className="font-skyBold text-blue-pure text-xl md:text-lg">Tech Specs</span></li>
                            <li className="inline-block mx-6"><a href="/products/upcoming-releases" className="text-xl md:text-lg">Upcoming Releases</a></li>
                        </ul>
                    </div>
                </div>
            )
        break;
        case "/products/sky-pod/tech-specs":
            return (
                <div className="bg-gray-light">
                    <div className="ml-0 lg:ml-16 lg:pl-6 xl:ml-16 xl:pl-12 overflow-x-scroll scrollbar-hide">
                        <ul className="w-[600px] md:w-auto">
                            <li className="inline-block mx-6"><a href="/products" className="text-xl md:text-lg"><BackArrow className="inline-block"/> Back to Overview</a></li>
                            <li className="inline-block mx-6"><a href="/products/sky-pod" className="text-xl md:text-lg">Sky Pod</a></li>
                            <li className="inline-block mx-6 pt-4 pb-2 border-b-2 border-blue-pure"><span className="font-skyBold text-blue-pure text-xl md:text-lg">Tech Specs</span></li>
                            <li className="inline-block mx-6"><a href="/products/upcoming-releases" className="text-xl md:text-lg">Upcoming Releases</a></li>
                        </ul>
                    </div>
                </div>
            )
        break;
        
        default:
            return <></>
        }
    }
    return null
}

export default SubMenu;
