import React, { ReactNode } from 'react';
import { Document, BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { Options, documentToReactComponents } from '@contentful/rich-text-react-renderer';

const renderOptions: Options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [...children, index > 0 && <br className = "sky-h4"key={index} />, textSegment],
        []
      ),

  renderMark: {
    [MARKS.BOLD]: (text: ReactNode) => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (_, children: ReactNode) => (
      <h1 className="py-2 mx-auto text-2xl md:text-3xl text-black">{children}</h1>
    ),
    [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p className="text-lg sky-h4" style={{color:"#00013A"}}>{children}</p>,
    [INLINES.HYPERLINK]: (node: any, children: ReactNode) => (
      <a href={node?.data?.uri} className="sky-text-daylight">
        {children}
      </a>
    ),
    [BLOCKS.OL_LIST]: (_, children: ReactNode) => (
      <div className="desk:px-6 pb-8 lg:px-0 mx-auto">
        <ol className="text-lg text-black orderedList desk:pl-10">{children}</ol>
       </div>
  ),
  [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => <ul className="text-lg unorderedList ul" style={{listStyleType: 'disc', color: '#00013A'}}><li style={{fontSize:"1.3rem"}}>{children}</li></ul>,
  // [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg text-black mb-6 unorderedList pl-6">{children}</ul>,
  
    [BLOCKS.UL_LIST]: (_, children: ReactNode) => <ul className="text-lg unorderedList">{children}</ul>,
  },
};

export const DocumentToReactComponents = ({ richtextEditorProps }: { richtextEditorProps?: Document }) => {
  return <>{richtextEditorProps && <>{documentToReactComponents(richtextEditorProps, renderOptions)}</>}</>;
};

