import React from "react";
import { HeadSection } from "../../components";
import { Banner, BundleCards, FeatureGrid, ProductHardwareFwVideoCta, RailCarouselGroup, SkyPodOverview, Table, WebContentGallery } from './components'
import { CardType } from "./utils";

export const NewSkyPodLayout: React.FC<{ content: any }> = ({ content }) => {

    const bundleRef = React.useRef<HTMLDivElement>(null);
    const productHardwareFwVideoCtaRef = React.useRef<HTMLDivElement>(null);
    const webContentGalleryRef = React.useRef<HTMLDivElement>(null);

    const childContent = content?.content?.fields?.content;
    const contentOrder = [] as any;
    const headerContent = [] as any;
    childContent?.map((module: any) => {
        switch (module?.sys?.contentType?.sys?.id) {
            case 'seo':
                headerContent.push(<HeadSection data={module.fields} />);
                break;
            case 'productHardwareSimpleBanner':
                headerContent.push(<Banner data={module.fields} handleBundleCardsScroll={() => productHardwareFwVideoCtaRef?.current?.scrollIntoView({ behavior: 'smooth' })} />);
                break;
            case 'bundleCardCollection':
                if (module.fields?.cardType === CardType.BUNDLE_PRODUCT) {
                    contentOrder.push(
                        <BundleCards
                            data={module.fields}
                            ref={bundleRef}
                        />,
                    );
                }
                if (module.fields?.cardType === CardType.POD_SUPPORT) {
                    contentOrder.push(<SkyPodOverview data={module.fields} />);
                }
                break;
            case 'productHardwareFwVideoCta':
                contentOrder.push(
                    <ProductHardwareFwVideoCta
                        data={module.fields}
                        ref={productHardwareFwVideoCtaRef}
                    />,
                );
                break;
            case 'productHardwareColumns':
                contentOrder.push(<FeatureGrid data={module.fields} />);
                break;
            case 'podWebContentGallery':
                contentOrder.push(
                    <WebContentGallery
                        ref={webContentGalleryRef}
                        data={module.fields}
                    />,
                );
                break;
            case 'productHardwareContentRailsGroup':
                contentOrder.push(<RailCarouselGroup data={module.fields} />);
                break;
            case 'productHardwareTable':
                contentOrder.push(<Table data={module.fields} />);
                break;
            default:
        }
        return null;
    });
    return (<div className="bg-custom-dark-blue flex flex-col gap-12 lg:gap-16 xl:gap-20 2xl:gap-[120px]">
        <div className="top-header-bg">
            {headerContent?.map((item: any, index: number) => <React.Fragment key={index}>{item}</React.Fragment>)}
        </div>
        {contentOrder?.map((item: any, index: number) => <React.Fragment key={index}>{item}</React.Fragment>)}
    </div>)
}