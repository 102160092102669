import React, { forwardRef } from 'react';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Button } from '@sky-tv-group/components';
import { useNavigate } from 'react-router-dom';

interface ProductHardwareFwVideoCtaProps {
  data?: object | any
}

export const ProductHardwareFwVideoCta = forwardRef<HTMLDivElement, ProductHardwareFwVideoCtaProps>(
  ({ data }, ref) => {
    const navigate = useNavigate();
    let HORIZONTAL_VIDEO_PLAYER_URL =
      'https://players.brightcove.net/6122285394001/Uk656dFgP_default/index.html?videoId=';
    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];

    const videoPlay = React.useRef() as any;
    const handleCTAButton = () => {
      if (data.ctaButtonText === 'Choose your plan') {
      } else {
        navigate('/sky-pod');
      }
    };
    return (
      <div className="w-full bg-custom-dark-blue">
        {data.productLogo?.fields?.file?.url && data.logoAboveText === true ? (
          <img src={data.productLogo.fields.file.url} alt={data.heading} className="mx-auto pb-4" />
        ) : null}
        {data.heading ? (
          <h2
            className={`font-normal text-white text-center mb-2 px-4 md:px-8 lg:px-12 ${data.brightCoveId ? 'uppercase text-[14px] leading-[14px] md:text-[18px] md:leading-[18px]' : 'text-[36px] font-extrabold leading-[32.4px] xl:text-[60px] xl:leading-[60px]'}`}>
            {data.heading}
          </h2>
        ) : null}
        {data.subHeading ? (
          <p className="text-[36px] font-extrabold leading-[32.4px] xl:text-[60px] xl:leading-[60px] mb-4 sm:mb-6 text-center text-white px-4 md:px-8 lg:px-12">
            {data.subHeading}
          </p>
        ) : null}
        {data.description ? (
          <h4 className="max-w-[632px] mx-auto text-[16px] leading-[20px] md:text-[20px] md:leading-[24px] my-0 py-0 px-4 md:px-0  text-center  text-white">
            {documentToReactComponents(data.description)}
          </h4>
        ) : null}
        {data.productLogo?.fields?.file?.url && data.logoAboveText !== true ? (
          <img src={data.productLogo.fields.file.url} alt={data.heading} className="mx-auto py-4" />
        ) : null}
        {data.brightCoveId ? (
          <div ref={ref} className="w-full mx-auto mt-4 md:mt-6 lg:mt-10 relative ">
            <div className="top-0 left-0 w-full aspect-video video-player">
              <ReactPlayerLoader
                accountId={accountId}
                videoId={data.brightCoveId}
                options={{
                  autoplay: data.autoplay,
                  muted: data.autoplay === false ? false : true,
                  loop: data.autoplay === false ? false : true,
                  playsinline: true,
                }}
                ref={videoPlay}
              />
            </div>
          </div>
        ) : (
          data?.videoPoster?.fields?.file?.url && (
            <img
              alt="video-poster"
              className="w-auto lg:min-w-[796px] h-auto mx-auto mb-8 px-4 lg:px-0"
              src={data.videoPoster?.fields.file.url}
            />
          )
        )}
        {data?.terms && <p className="relative w-full my-4 sm:mt-10 sm:mb-6 px-4 sky-mark-bold-16 xl:text-[20px] xl:leading-6 text-center text-white">
          {documentToReactComponents(data.terms)}
        </p>}
        {data.ctaButtonLink ? (
          <div className="text-center">
            <Button className="w-[216px] h-[42px] xl:h-12" onClick={() => handleCTAButton()}>
              {data.ctaButtonText}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
);
