import React from "react";
import {
  HORIZONTAL_VIDEO_PLAYER_URL,
  //VERTICAL_VIDEO_PLAYER_URL,
} from "../config";
import { DocumentToReactComponents } from "./DocumentToReactComponents";
import { useNavigate } from "react-router-dom";

interface ComponentCardSection {
  heading?: string;
  cardImageUrl?: {
    url?: string;
    text?: string;
  };
  cardImagePosition?: string;
  cardImageLinkUrl?: string;
  cardTitle?: string;
  cardSubTitle?: string;
  cardDescription?: string;
  cardDescriptionTextAlignment?: string;
  cardDetailedDescription?: JSON;
  cardDetailedDescriptionAlignment?: string;
  brightCoveVideoId?: string;
  linkText?: string;
  linkUrl?: string;
  links?: {
    internalName?: string;
    linkText?: string;
    linkUrl?: string;
    linkDocument?: {
      url?: string;
      text?: string;
    };
  };
}
interface ComponentCardProps {
  componentCardSectionTitle: string;
  componentCards: ComponentCardSection[];
  componentCardDisplayTile: string;
  subTitle: string;
}

export const ComponentCardCollection = ({
  componentCardSectionTitle,
  componentCards,
  componentCardDisplayTile,
  subTitle,
}: ComponentCardProps) => {
  let navigate = useNavigate();
  let displayTile;
  switch (componentCardDisplayTile.replace(" ", "")) {
    case "Twotile":
      displayTile = "grid-cols-2 gap-4";
      break;

    case "Threetile":
      displayTile = "grid-cols-3 gap-4";
      break;

    case "Fourtile":
      displayTile = "grid-cols-4 gap-0";
      break;

    default:
      displayTile = "grid-cols-1 gap-0";
      break;
  }
  return (
    <>
    {componentCardSectionTitle && componentCardSectionTitle.length > 0 && (
      <><div
          className="sky-h2 md:sky-h2-bold sky-text-midnight mt-8 text-center text-5xl px-10 p-2"
          style={{ fontFamily: "MarkPro-Bold SKY" }}
          dangerouslySetInnerHTML={{
            __html: componentCardSectionTitle as string,
          }}
        ></div><div className="text-center text-[1.125rem] mt-3 px-10" style={{ color: "#00013A", fontFamily: 'MarkPro-Book SKY' }}>{subTitle as string}</div></>
    )
  }
      <div className="sky-h6 md:sky-h6 text" style={{ color: "#00013A" }}>
        <div className={`mt-16 pb-4 grid lg:${displayTile} md:${displayTile}`}>       
        {componentCards?.map((componentCardCollection: any) => {
            return (            
              <div
                className="card overflow-hidden shadow-none pb-4"
                style={{ borderRadius: 0}}
              >
                <div>
                  {componentCardDisplayTile && componentCardDisplayTile === "Four tile" && componentCardCollection && componentCardCollection?.fields?.cardImageUrl && (
                    <div className="mx-8 items-center">
                    <div className="flex flex-wrap flex-col items-center">
                      <img className="flex" src={componentCardCollection?.fields?.cardImageUrl?.fields?.file?.url}
                      style={{
                        // borderTopLeftRadius: 0,
                        // borderTopRightRadius: 0,  
                        // width: "45rem",                     
                        height: "152.996px",
                      }} />
                    <p className="mt-2 text-center sky-h4 pb-0" style={{fontFamily: 'MarkPro-Bold SKY', color: '#00013A'}}>{componentCardCollection?.fields?.cardTitle}</p>
                    </div>
                    </div>
                  )}
                </div>

                <div> 
                {
                  <p className="text-darkText font-semibold text-[1.2rem]" style={{color: '#00013A', fontFamily: 'MarkPro-Book SKY'}}
                  dangerouslySetInnerHTML={{
                    __html: componentCardCollection?.heading as string,
                  }}
                ></p>
                }               
                
                {componentCardCollection?.cardDetailedDescription && (
                  <div
                    style={{
                      height: componentCardCollection?.linkUrl && "220px",
                    }}
                  >
                    <p>
                      <DocumentToReactComponents
                        richtextEditorProps={
                          componentCardCollection?.cardDetailedDescription
                        }
                      />
                    </p>
                  </div>
                )}             
       </div>
                </div>        
                )
        })}
      </div>
      </div>
    </>
  );
};

export default ComponentCardSection;
