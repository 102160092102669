// src/components/FeatureGrid.tsx
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
interface FeatureGridProps {
  data?: object | any;
}

export const FeatureGrid: React.FC<FeatureGridProps> = ({ data }) => {
  let columns = data.columns;
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 xl:gap-4 px-4 py-10 xl:px-20 xl:py-10">
      {columns.map((item: any, index: number) => (
        <div
          key={index}
          className="flex flex-col items-center px-4 xl:px-8 gap-4 md:max-w-sm rounded-lg">
          <img
            className="rounded-lg max-w-full max-h-60 object-cover"
            src={item.fields.image.fields.file.url}
            alt={item.fields.subtitle}
          />
          <div className="text-[16px] font-[900] leading-[20px] text-white text-center">{item.fields.subtitle}</div>
          <div className="text-[12px] font-[450] leading-[15px] text-primary-day-light-50 text-center">
            {documentToReactComponents(item.fields.description)}
          </div>
        </div>
      ))}
    </div>
  );
};

