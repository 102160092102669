export function splitPrice(price: string): [string, string] {
    // Split the price string by the decimal point
    const [integralPart, decimalPart] = price.split('.');

    // If the decimal part is undefined, set it to an empty string
    return [integralPart, decimalPart !== undefined ? decimalPart : ''];
}

export function formatToTwoDecimalPlaces(value: number | string): string {
    // Convert the input to a number
    const numberValue = typeof value === 'string' ? parseFloat(value) : value;

    // Return the original value if the conversion is invalid
    if (isNaN(numberValue)) {
        return value + '';
    }

    // Format the number to two decimal places
    return numberValue.toFixed(2);
}

export const CardType = {
    BUNDLE_PRODUCT: 'Bundle Product',
    SUPPORT: 'Support',
    POD_SUPPORT: 'Pod support',
  };