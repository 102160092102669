import React, { forwardRef } from 'react';
import RailCards from './rail-cards/rail-cards';
import { Settings as ReactSlickSettings } from 'react-slick';
import { Button } from './button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { formatToTwoDecimalPlaces, splitPrice } from '../utils';
import classNames from 'classnames';
const customSettings: ReactSlickSettings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 2,
    swipeToSlide: true,
    variableWidth: true,
    infinite: true,
    arrows: false,
};

interface BundleCardsProps {
    data: object | any;
}

const BundleCardContentTile: React.FC<BundleCardsProps> = ({ data }) => {
    let productCard = data;
    let heading = productCard?.title;
    let description = productCard?.termsAndConditionsCaption;
    let logoImage = productCard?.thumbnail?.fields?.file?.url; //Sky BB Icon
    let symbolImage = productCard?.titleLogo?.fields?.file?.url;
    let discountedPrice = formatToTwoDecimalPlaces(productCard?.discountedPrice ?? productCard?.price);
    let price = formatToTwoDecimalPlaces(productCard?.price);
    let ctaButtonText = productCard?.ctaName;
    let ctaButtonLink =  productCard?.ctaLink;
    const [integralPart, decimalPart] = splitPrice(discountedPrice);
    return (
        <div className="flex flex-col w-full h-full">
            {heading && !logoImage && <div className="text-[16px] font-skyBold leading-[20px] xl:text-[20px] xl:leading-6 grow pt-2">{heading}</div>}
            {heading && logoImage && (
                <div className="flex flex-row items-center grow">
                    {symbolImage && <img src={symbolImage} alt="Sky Pod" className="max-w-full h-[45px] object-contain" />}
                    <div className="flex items-start flex-col pl-4">
                        <p className="text-[14px] leading-[17.5px]">{heading}</p>
                        {logoImage && <img src={logoImage} alt="Sky Pod" className="max-w-full h-[30px] object-contain" />}
                    </div>
                </div>
            )}
            <div className="flex flex-row justify-between items-end mt-2 xl:mt-6 mb-2">
                <div>
                    {price && price !== discountedPrice && <del className='text-[12px]'>${price}</del>}
                <div className="flex justify-start">
                    <div className="pt-2">
                        <div className="text-[12px] font-skyBook leading-[15px]">$</div>
                    </div>
                    <div className="text-[36px] font-skyBlack font-black leading-[43.2px]">{integralPart}</div>
                    <div className="py-1 flex-col">
                        <div className="text-[16px] font-skyBook leading-[20px]">{'.' + decimalPart}</div>
                        <div className="text-[12px] font-skyBook leading-[15px]">/mth</div>
                    </div>
                </div>
                </div>
                <Button
                    onClick={() => window.open(ctaButtonLink)}
                    className={classNames('bg-bright-blue text-white rounded-full w-[150px] h-[42px]')}>
                    {ctaButtonText}
                </Button>
            </div>
            <div className="text-[10px] w-full">{documentToReactComponents(description)}</div>
        </div>
    );
};
const BundleCardComponent: React.FC<BundleCardsProps> = ({ data }) => {
    let headerImage = data?.headerimage?.fields?.file?.url;
    let productImage = data?.productImage?.fields.file.url;
    let bundleCardContentTile = data?.bundleCardContentTile;
    let tileImages = data?.tileImages?.fields?.contentTiles ?? [];
    const imagesArrayNew = tileImages.map(
        (item: { fields: { image: { fields: { file: { url: any } } } } }) => item.fields.image.fields.file.url,
    );
    const bundleTermAndConditions = data?.termsAndConditionsCaption;
    return (
        <div className="flex-1 rounded-lg overflow-hidden bg-primary-dark-blue border-[1px] border-[#363fbc]">
            <img
                src={headerImage}
                alt={data?.internalName}
                className="max-w-full h-[40px] object-contain mt-[10px] mb-[12px] lg:ml-[20px]"
            />
            <div>
                <img src={productImage} alt={data?.internalName} className="w-full h-auto" />
            </div>
            <RailCards
                images={imagesArrayNew}
                sliderSettings={customSettings}
                className="w-16 h-9 xl:w-[127px] xl:h-[72px]"
                bundleRailCards={true}
            />
            <div className="px-5 lg:px-20 xl:px-10 2xl:px-20 pb-4 xl:pb-4 flex flex-col justify-start items-start text-left xl:flex-row w-full gap-4 xl:gap-8 2xl:gap-12 xl:h-[196px] xl:mt-4">
                {bundleCardContentTile.map((item: any, index: number) => (
                    <BundleCardContentTile
                        key={index}
                        data={item.fields}
                    />
                ))}
            </div>
            {bundleTermAndConditions && <p className='px-5 lg:px-20 xl:px-10 2xl:px-20 pb-6 xl:pb-10 text-[10px] text-white'>
                {documentToReactComponents(bundleTermAndConditions)}
            </p>}
        </div>
    );
};

export const BundleCards = forwardRef<HTMLDivElement, BundleCardsProps>(
    ({ data }, ref) => {
        return (
            <>
                {data?.heading && <h1 className='text-white text-center self-center max-w-[854px] text-[36px] md:text-[60px] font-skyBlack font-bold leading-[36px] md:leading-[60px] mb-12'>{data?.heading}</h1>}
                <div ref={ref} className="text-white 2xl:px-20 component-gradient md:mt-[-40px]">
                    <div className="flex flex-col-reverse md:flex-row gap-6 md:gap-2.5 lg:gap-[18px] xl:gap-4">
                        {data?.bundleCards.map((item: any, index: number) => (
                            <BundleCardComponent
                                key={index}
                                data={item.fields}
                            />
                        ))}
                    </div>
                </div>
            </>
        );
    },
);
