
import { 
    Columns, 
    ContentRail, 
    ContentRailsGroup,
    FaqList, 
    Table, 
    FullWidthVideo,
    ProductHardwareFwVideoCta,
    ProductHardwareTabbedChooser,
    HeadSection, 
    HeroBanner,
    ShortHeroBanner,
    InPageTerms,
    Heading,
    VideoImageLink,
    ProductCardCarousel,
    ProductPromoCallUsCta,
    SubMenu,
    VideoAccordian,
    JoinNowCTA,
    GreyFaqList,
    LandingBanner,
    OfferPanel,
    FeatureSlider,
    // TestimonialPanel,
    // ContactUsPanel
} from '../components'
import { ComponentCardCollection } from '../components/ComponentCardCollection';
import { FreeFormContent } from '../components/FreeformContent';
import { LogoContentAccordion } from '../components/LogoContentAccordion';


import { ErrorPage } from '../templates'

const Layout = ( data: any ) => {

    if(!data || data.content.status === false ) {
        return <ErrorPage/>
    }

    // console.log( data)
    const contentOrder =[] as any;
    const contentOffers =[] as any;
    
    // NSB Static
    let heroBanner = [] as any
    let contentFaqs = [] as any
    let contentRails = [] as any

    data.content.content?.map((module: any)=> {
        
        switch(module.sys.contentType.sys.id) {
            case 'seo':
                contentOrder.push(<HeadSection data={module.fields}/>)
            break;
            case 'productHardwareBanner':
                if(module.fields.shortBanner !== true) {
                    contentOrder.push(<HeroBanner data={module.fields}/>)
                    contentOffers.push(<InPageTerms data={module.fields.offers}/>)
                    heroBanner = module.fields;
                }
                else {
                    contentOrder.push(<ShortHeroBanner data={module.fields}/>)
                    heroBanner = module.fields;
                }
            break;
            case 'productPromoJoinNowCta':
                contentOrder.push(<JoinNowCTA data={module.fields}/>)
            break;
            // case 'productHardwareFullWidthVideo':
            //     contentOrder.push(<FullWidthVideo data={module.fields}/>)
            // break;
            case 'productHardwareFwVideoCta':
                contentOrder.push(<ProductHardwareFwVideoCta data={module.fields}/>)
            break;
            case 'productHardwareTabbedChooser':
                contentOrder.push(<ProductHardwareTabbedChooser data={module.fields}/>)
            break;
            // case 'productHardwareContentRail':
            //     contentOrder.push(<ContentRail data={module.fields} size="large" />)
            //     contentRails.push(module.fields)
            // break;
            case 'productHardwareContentRailsGroup':
                contentOrder.push(<ContentRailsGroup data={module.fields}/>)
                contentRails.push(module.fields)
            break;
            case 'productHardwareFaqList':
                if(module.fields.greyBackground !== true) {
                    contentOrder.push(<FaqList data={module.fields}/>)
                    contentFaqs = module.fields;
                }
                else {
                    contentOrder.push(<GreyFaqList data={module.fields}/>)
                    contentFaqs = module.fields;
                }
            break;
            case 'productHardwareColumns':
                contentOrder.push(<Columns data={module.fields}/>)
            break;
            case 'productHardwareTable':
                contentOrder.push(<Table data={module.fields}/>)
            break;
            case 'productHardwareTerm':
                contentOrder.push(<Heading data={module.fields}/>)
            break;
            case 'productHardwareVideo':
                contentOrder.push(<VideoImageLink data={module.fields}/>)
            break;
            case 'cardPanel':
                // console.log(module)
                contentOrder.push(<ProductCardCarousel data={module.fields}/>)
            break;
            case 'dynamicNpmModule':
                contentOrder.push(<VideoAccordian data={module.fields}/>)
            break;
            case 'productPromoCallUsCta':
                contentOrder.push(<ProductPromoCallUsCta data={module.fields}/>)
            break;
            case 'landingBannerPanel':
                contentOrder.push(<LandingBanner data={module.fields}/>)
            break;
            case 'landingOfferPanel':
                contentOrder.push(<OfferPanel data={module.fields}/>)
            break;
            case 'landingFeatureSlider':
                contentOrder.push(<FeatureSlider data={module.fields}/>)
            break;
            case 'componentCardSection':
                contentOrder.push(<ComponentCardCollection componentCardSectionTitle={module.fields.componentCardSectionTitle as string} componentCards={module?.fields?.componentCards[0]?.fields?.componentCards} componentCardDisplayTile={module.fields?.componentCardDisplayTile} subTitle={module.fields.subTitle}/>)
                break;
            case 'freeformContent':
                contentOrder.push(<FreeFormContent value={module.fields.content} heading={module.fields.heading}/>)
                break;
            case 'logoContentAccordion':
                contentOrder.push(<LogoContentAccordion data={module.fields}/>)
            break;
            // case 'productPromoCallUsCta':
            //     contentOrder.push(<TestimonialPanel data={module.fields}/>)
            // break;
            // case 'productPromoCallUsCta':
            //     contentOrder.push(<ContactUsPanel data={module.fields}/>)
            // break;
            default:
        }
        return null
    });

    // console.log(data)
    // if(data.content.pageSlug !== "/products/new-sky-box") {

        

        return (
            <>
            <main>
                <SubMenu data={data} />
                {contentOrder?.map((item: any, index: number)=>(
                    <div key={index}>
                        {item}
                    </div>
                )
                )}
                {contentOffers?.map((item: any, index: number)=>(
                    <div key={index}>
                        {item}
                    </div>
                )
                )}
            </main>
            </>
        )
    // }
    // else {
    //     return (
    //         <>
    //         <main>
    //             <SubMenu data={data} />
    //             <NewSkyBoxPage heroBanner={heroBanner} contentRails={contentRails} contentFaqs={contentFaqs} data={data}/>
    //         </main>
    //         </>
    //     )
    // }
};

export default Layout;